import React, { useState, useEffect, useRef } from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import {
  TextField,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  Grid,
  Snackbar,
  Box,
} from "@mui/material";
import { Button } from "gatsby-theme-material-ui";
import CloseIcon from "@mui/icons-material/Close";
import PageLayout from "../../components/pageLayout";
import Dialog from "../../components/dialog";
import { setupScene } from "../../actions/sceneActions/sceneLoader";
import Rotate3DIcon from "../../icons/rotate_3d.svg";
import ScrollIcon from "../../icons/scroll.svg";
import LMBIcon from "../../icons/lmb.svg";
import TouchMoveIcon from "../../icons/touch_move.svg";
import TouchPinchIcon from "../../icons/touch_pinch.svg";
import Loading from "../../components/loading";
import { genHelmet } from "../../actions/baseActions";

import theme from "../../gatsby-theme-material-ui-top-layout/theme";

const styles = {
  root: {
    // background: '#fff5f0'
  },
  viewport: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "transparent",
    width: "100vw",
    height: "100vh",
  },
  passwordWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
  },
  footerControls: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    pointerEvents: "none",
    paddingLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      pointerEvents: "initial",
    },
  },
  dialog: {
    "& > div.MuiBackdrop-root": {
      display: "none",
    },
    "& div.MuiPaper-root": {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      backdropFilter: "blur(4px)",
      border: "1px solid rgba( 255, 255, 255, 0.18 )",
      color: "#fff",
    },
  },
  howToIcon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: theme.spacing(1),
  },
  snackbar: {
    "& > div > div.MuiSnackbarContent-message": {
      width: "100%",
    },
    "& > div.MuiPaper-root": {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      backdropFilter: "blur(4px)",
      border: "1px solid rgba( 255, 255, 255, 0.18 )",
      color: "#fff",
    },
  },
  snackImg: {
    width: "100%",
    margin: `${theme.spacing(2)} 0`,
  },
  snackButton: {
    color: "#fff",
    background: "transparent",
    border: "1px solid #fff",
    // fontFamily: 'DM Sans,DM Mono,sans-serif,monospace'
  },
  tooltip: {
    "& > div.MuiTooltip-tooltip": {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      backdropFilter: "blur(4px)",
      border: "1px solid rgba( 255, 255, 255, 0.18 )",
      color: "#fff",
    },
  },
};

const password = "TULEVA";

const WIP = (props) => {
  const viewport = React.useRef();
  const [enteredPassword, setEnteredPassword] = useState("");
  const [howToOpen, setHowToOpen] = useState(false);
  const [itemInfoOpen, setItemInfoOpen] = useState(false);
  const [intersect, setIntersect] = useState();
  const [totalAssets, setTotalAssets] = useState(null);
  const [loadedAssets, setLoadedAssets] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const loadedAssetsRef = useRef();
  const renderRef = useRef();
  loadedAssetsRef.current = loadedAssets;

  // default image if no 3D
  const snackImg = props.data.contentfulProject.coverImage;

  const addAsset = (uuid) => {
    loadedAssetsRef.current++;
    setLoadedAssets(loadedAssetsRef.current);
  };

  // setupScene
  useEffect(() => {
    if (!viewport?.current) return;
    // const sceneId = '9684511A-097B-471B-B87A-441D9E1F51C9' // floating man
    // const sceneId = '1A8DA736-6FD5-4E64-B2F2-EC0B63F60A5E' // chandelier (to be updated)
    const sceneId = "C77FB369-DEAA-45CA-ADFF-371541AB5F7E"; // tuleva
    // const sceneId = 'DE15063E-CDA9-4532-92A3-AFE56A5B2B62' // ETD showroom

    const renderSettings = {
      type: "TOON", // ENUMs: 'RECT_LIGHT', 'SHADER', 'TOON', 'GLASS', WEBGL
      bloomParams: {
        // exposure: 1,
        bloomStrength: 0.65,
        bloomThreshold: 0,
        bloomRadius: 0.5,
      },
      controlsType: "ORBIT_CONTROLS",
      orbitControlsSettings: {
        center: [0, 5, 0],
        // maxPolarAngle: 0.55,
        // minPolarAngle: undefined,
        minDistance: 500,
        maxDistance: 5000,
        panSpeed: 0,
      },
      toonBorder: {
        color: [255, 255, 255],
        thickness: 0.005,
      },
    };

    // sets up the render
    const runScene = async () => {
      const {
        scene,
        camera,
        render,
        animate,
        renderer,
        controls,
        raycaster,
        destroy,
      } = await setupScene(viewport, {
        sceneId,
        renderSettings,
        setupLoadingManager: (bvs) => {
          setTotalAssets(bvs.geometryUuids.length + bvs.imageUuids.length);
        },
        onAssetLoad: (uuid) => {
          addAsset(uuid);
        },
      });
      // intersects
      const mouse = { x: 0, y: 0 };

      // TODO - need a data structure for intersections
      // const clickableMeshUuids = [
      //   "8675B77D-CFE5-487F-98A3-4ACE3C0279AF",
      //   "9166C8E7-8B1D-491A-9F74-4AADE8F44EC3",
      //   "67D746C1-B068-40C2-9C4A-6F4DDA978862",
      //   "8B5D90F8-F1A3-43CA-B0B4-394AC197F560",
      // ];
      // const clickableMeshes = [];
      // const intersectable = scene.traverse((obj) => {
      //   if (clickableMeshUuids.includes(obj.uuid)) clickableMeshes.push(obj);
      // });
      // const onMouseMove = (ev) => {
      //   if (ev.targetTouches) {
      //     if (ev.targetTouches.length > 1) return;
      //     mouse.x =
      //       (ev.targetTouches[0].clientX / viewport.current.clientWidth) * 2 -
      //       1;
      //     mouse.y =
      //       -(ev.targetTouches[0].clientY / viewport.current.clientHeight) * 2 +
      //       1;
      //   } else {
      //     mouse.x = (ev.clientX / viewport.current.clientWidth) * 2 - 1;
      //     mouse.y = -(ev.clientY / viewport.current.clientHeight) * 2 + 1;
      //   }
      //   // console.log(mouse)
      //   raycaster.setFromCamera(mouse, camera);
      //   const intersects = raycaster.intersectObjects(clickableMeshes);
      //   if (intersects.length > 0) {
      //     setIntersect(intersects[0]);
      //   } else setIntersect(null);
      // };

      // if (typeof window !== undefined) {
      //   window.addEventListener("mousemove", onMouseMove, false);
      //   window.addEventListener("touchstart", onMouseMove, false);
      // }

      renderRef.current = render;

      return { destroy };
    };

    const sceneData = runScene();

    return async () => {
      // Cleanup
      const { destroy } = await sceneData;
      // if (typeof window !== undefined) {
      //   window.removeEventListener("mousemove", onMouseMove, false);
      //   window.removeEventListener("touchstart", onMouseMove, false);
      // }
      if (destroy) destroy();
    };
  }, [enteredPassword, viewport]);

  // moniter loadingManager
  useEffect(() => {
    if (isLoaded) return;
    if (totalAssets === null) return;
    if (loadedAssets >= totalAssets) {
      setIsLoaded(true);
      if (renderRef.current) renderRef.current();
    }
  }, [loadedAssets, totalAssets, isLoaded]);

  const handleClick = (ev) => {
    if (intersect) setItemInfoOpen(true);
    else setItemInfoOpen(false);
  };

  return (
    <>
      {genHelmet(props.data.contentfulProject, {
        type: "project",
        siteData: props.pageContext.site,
        path: props.path,
      })}
      <PageLayout location={props.location} hideFooter>
        {password !== enteredPassword ? (
          <Box sx={styles.passwordWrapper}>
            <TextField
              variant="outlined"
              label="Password"
              type="password"
              value={enteredPassword}
              onInput={(ev) => setEnteredPassword(ev.target.value)}
            />
          </Box>
        ) : (
          <>
            <Box
              ref={viewport}
              sx={styles.viewport}
              style={intersect ? { cursor: "pointer" } : {}}
              onClick={handleClick}
              onTouchEnd={handleClick}
            />
            {!isLoaded && <Loading />}
          </>
        )}
        <Box sx={styles.footerControls}>
          <Tooltip
            PopperProps={{ sx: styles.tooltip }}
            title="how to navigate"
            placement="right"
          >
            <IconButton onClick={() => setHowToOpen(true)} size="large">
              <SvgIcon component={Rotate3DIcon} htmlColor="#fff" />
            </IconButton>
          </Tooltip>

          {/*<Tooltip
          PopperProps={{ className: classes.tooltip }}
          title="product information"
          placement="left"
        >
          <IconButton onClick={() => setItemInfoOpen(true)}>
            <SvgIcon component={InfoIcon} htmlColor="#fff" />
          </IconButton>
        </Tooltip>*/}
        </Box>
      </PageLayout>

      {/* ----ITEM INFO---- */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={itemInfoOpen}
        onClose={() => setItemInfoOpen(false)}
        sx={styles.snackbar}
        message={
          <Grid container direction="column">
            <Grid item container justifyContent="space-between" direction="row">
              <Typography>Enter the Dragon</Typography>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                edge="end"
                onClick={() => setItemInfoOpen(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                designed by Bureau V Architecture
              </Typography>
            </Grid>
            <GatsbyImage
              image={getImage(snackImg.thumbnail)}
              title={snackImg.title}
              alt={snackImg.title + ", " + snackImg.description}
              style={styles.snackImg}
            />
            <Grid item container justifyContent="center">
              <Button
                href="/projects/enter-the-dragon"
                sx={styles.snackButton}
                variant="outlined"
              >
                Learn more
              </Button>
            </Grid>
          </Grid>
        }
      />

      {/* ----NAVIGATION DIALOG---- */}
      <Dialog
        title="How To Navigate"
        open={howToOpen}
        handleClose={() => {
          setHowToOpen(false);
        }}
        sx={styles.dialog}
      >
        <Grid container spacing={4} alignItems="flex-start" direction="row">
          <Grid item container xs={6} direction="column" alignItems="center">
            <SvgIcon
              component={ScrollIcon}
              htmlColor="#fff"
              sx={styles.howToIcon}
            />
            <Typography variant="body2" align="center">
              Scroll to zoom in
            </Typography>
          </Grid>

          <Grid item container xs={6} direction="column" alignItems="center">
            <SvgIcon
              component={LMBIcon}
              htmlColor="#fff"
              sx={styles.howToIcon}
            />
            <Typography variant="body2" align="center">
              Click & drag to move
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="flex-start" direction="row">
          <Grid item container xs={6} direction="column" alignItems="center">
            <SvgIcon
              component={TouchPinchIcon}
              htmlColor="#fff"
              sx={styles.howToIcon}
            />
            <Typography variant="body2" align="center">
              Pinch to zoom in
            </Typography>
          </Grid>

          <Grid item container xs={6} direction="column" alignItems="center">
            <SvgIcon
              component={TouchMoveIcon}
              htmlColor="#fff"
              sx={styles.howToIcon}
            />
            <Typography variant="body2" align="center">
              Touch & drag to move
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default WIP;

// needs to be updated
export const pageQuery = graphql`
  {
    contentfulProject(slug: { eq: "enter-the-dragon" }) {
      title
      headline
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
    }
  }
`;
